import { useState } from 'react';
import { Trans } from 'react-i18next';
import { EmailValidationModal } from '../Modals/EmailValidationModal';

interface HomeTryProps {
    children?: React.ReactNode,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function HomeTry({ children }: HomeTryProps) {
    const [showRegistration, setShowRegistration] = useState(false);

    return (
        <>
            <EmailValidationModal isOpen={showRegistration} onClose={() => setShowRegistration(false)} />

            <section className="home-try">
                <div className="home-try__body">
                    <h2 className="home-try__heading">
                        <Trans>Get free access to try out the platform</Trans>
                    </h2>
                    <p className="home-try__cta">
                        <a
                            href="#requestaccess"
                            className="btn _big"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowRegistration(true);
                            }}>
                            <Trans>Get Access</Trans>
                        </a>
                    </p>
                </div>
            </section>
        </>
    )
}

export default HomeTry;