import { t } from 'i18next';
import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { EMPTY_ROLE } from './components/common';
import { UploadFilesContext, UploadFilesContextType } from './components/downloads/UploaderContext';
import PrivateOutlet from './components/mplat/PrivateOutlet';
import { Spinner } from './components/mplat/Spinner';
import { useAuth } from './hooks';
import './langs/i18n';
import IndexPage from './pages/IndexPage';
import NotFoundPage from './pages/NotFoundPage';
import ResetFormPage from './pages/ResetFormPage';
import { setCredentials, setRole } from './services/api';
import { UnitUpload, UPLOAD_STATE } from './services/content_v1';


const AccountSelectionPage = React.lazy(() => import('./pages/AccountSelectionPage'));
const AccountInfoPage = React.lazy(() => import('./pages/AccountInfoPage'));
const AccountEditorPage = React.lazy(() => import('./pages/AccountEditorPage'));
const AccountViewerPage = React.lazy(() => import('./pages/AccountViewerPage'));
const AccountsManagePage = React.lazy(() => import('./pages/AccountsManagePage'));
const UsersPage = React.lazy(() => import('./pages/UsersPage'));
const ContactsPage = React.lazy(() => import('./pages/ContactsPage'));
const GroupListPage = React.lazy(() => import('./pages/GroupListPage'));
const GroupPage = React.lazy(() => import('./pages/GroupPage'));
const HelpPage = React.lazy(() => import('./pages/HelpPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const OrdersListPage = React.lazy(() => import('./pages/OrdersListPage'));
const AccessViewerPage = React.lazy(() => import('./pages/AccessViewerPage'));
// const UserListPage = React.lazy(() => import('./pages/UserListPage'));
const UserEditorPage = React.lazy(() => import('./pages/UserEditorPage'));
const SubscribersPage = React.lazy(() => import('./pages/SubscribersPage'));
const ConsumerViewerPage = React.lazy(() => import('./pages/ConsumerViewerPage'));
const ContentViewerPage = React.lazy(() => import('./pages/ContentViewerPage'));
const ContentEditorPage = React.lazy(() => import('./pages/ContentEditorPage'));
const ContentTVEditorPage = React.lazy(() => import('./pages/ContentTVEditorPage'));
const ContentListPage = React.lazy(() => import('./pages/ContentListPage'));
const ContentHistoryPage = React.lazy(() => import('./pages/ContentHistoryPage'));
const NotificationListPage = React.lazy(() => import('./pages/NotificationListPage'));
const OrganizationsPage = React.lazy(() => import('./pages/OrganizationsPage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const PermissionsPage = React.lazy(() => import('./pages/PermissionsPage'));
const RequestListPage = React.lazy(() => import('./pages/RequestListPage'));
const ToolsPage = React.lazy(() => import('./pages/ToolsPage'));
const TariffPage = React.lazy(() => import('./pages/TariffPage'));

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        delete localStorage.user;
        delete localStorage.account;
        delete localStorage.suUser;
        delete localStorage.suRefresh;
        delete localStorage.refreshToken;
        delete localStorage.accessToken;
        dispatch(setCredentials({ user: '', account: '', accessToken: '', refreshToken: '' }));
        dispatch(setRole(EMPTY_ROLE));
        navigate('/', { replace: true });
    }, [navigate, dispatch]);

    return <>Выходим...</>;
};

function AppMain() {
    const [uploadFiles, setUploadingFiles] = useState<{ [key: string]: UnitUpload }>({});
    const { config } = useContext<UploadFilesContextType>(UploadFilesContext);
    const dispatch = useDispatch();
    const auth = useAuth();

    const onRefreshChange = useCallback((ev: StorageEvent) => {
        if (ev.key === 'refreshToken' && ev.oldValue !== ev.newValue) {
            dispatch(setCredentials({ refreshToken: ev.newValue || undefined }));
        }
        if (ev.key === 'accessToken' && ev.oldValue !== ev.newValue) {
            dispatch(setCredentials({ accessToken: ev.newValue || undefined }));
        }
        if (ev.key === 'account' && ev.oldValue !== ev.newValue) {
            auth.account && setTimeout(() => window.location.reload(), 125);
        }
        // if (ev.key === 'user' && ev.oldValue !== ev.newValue) {
        //     auth.account && setTimeout(() => window.location.reload(), 125);
        // }
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener('storage', onRefreshChange);

        return () => {
            window.removeEventListener('storage', onRefreshChange);
        }
    }, [onRefreshChange]);

    const onClose = useCallback((ev: BeforeUnloadEvent) => {
        if (Object.keys(uploadFiles).length > 0 && Object.values(uploadFiles).find((u) => u.state === UPLOAD_STATE.UPLOADING) && Object.values(uploadFiles).find((u) => !!u.src)) {
            ev.preventDefault();
            const message = t('component:UPLOADING_ON_CLOSE_PAGE');
            ev.returnValue = message;
            return message;
        }
    }, [uploadFiles, t]);

    useEffect(() => {
        window.addEventListener('beforeunload', onClose);

        return () => {
            window.removeEventListener('beforeunload', onClose);
        }
    }, [onClose]);

    return <main className="main">
        <UploadFilesContext.Provider value={{ uploadFiles, setUploadingFiles, config }}>
            <Routes>
                <Route path="/login" element={<IndexPage />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/reset-confirm" element={<ResetFormPage reset={true} />} />
                <Route path="/activate-account" element={<ResetFormPage />} />
                <Route path="/contacts" element={<Suspense fallback={<Spinner />}><ContactsPage /></Suspense>} />
                <Route path="/help" element={<Suspense fallback={<Spinner />}><HelpPage /></Suspense>} />
                <Route path="/" element={<PrivateOutlet />}>
                    <Route index element={<Suspense fallback={<Spinner />}><DashboardPage /></Suspense>} />
                    <Route path="/account" element={<Suspense fallback={<Spinner />}><AccountInfoPage /></Suspense>} />
                    <Route path="/accounts" element={<Suspense fallback={<Spinner />}><AccountsManagePage /></Suspense>} />
                    <Route path="/accounts/view/:id" element={<Suspense fallback={<Spinner />}><AccountViewerPage /></Suspense>} />
                    <Route path="/accounts/:id" element={<Suspense fallback={<Spinner />}><AccountEditorPage /></Suspense>} />
                    <Route path="/access" element={<Suspense fallback={<Spinner />}><OrdersListPage /></Suspense>} />
                    <Route path="/access/permissions" element={<Suspense fallback={<Spinner />}><PermissionsPage /></Suspense>} />
                    <Route path="/access/:id" element={<Suspense fallback={<Spinner />}><AccessViewerPage /></Suspense>} />
                    {/* <Route path="/admin" element={<Suspense fallback={<Spinner />}><UserListPage /></Suspense>} /> */}
                    <Route path="/subscribers" element={<Suspense fallback={<Spinner />}><SubscribersPage /></Suspense>} />
                    <Route path="/consumers" element={<Suspense fallback={<Spinner />}><SubscribersPage /></Suspense>} />
                    <Route path="/consumers/:id" element={<Suspense fallback={<Spinner />}><ConsumerViewerPage /></Suspense>} />
                    <Route path="/content/view/:id" element={<Suspense fallback={<Spinner />}><ContentViewerPage /></Suspense>} />
                    <Route path="/content/history/:id" element={<Suspense fallback={<Spinner />}><ContentHistoryPage /></Suspense>} />
                    <Route path="/content/media/:id" element={<Suspense fallback={<Spinner />}><ContentEditorPage /></Suspense>} />
                    <Route path="/content/tv/:id" element={<Suspense fallback={<Spinner />}><ContentTVEditorPage /></Suspense>} />
                    <Route path="/content/tv" element={<Suspense fallback={<Spinner />}><ContentListPage type={'tv'} /></Suspense>} />
                    <Route path="/content/media" element={<Suspense fallback={<Spinner />}><ContentListPage type={'media'} /></Suspense>} />
                    <Route path="/content" element={<Suspense fallback={<Spinner />}><ContentListPage /></Suspense>} />
                    <Route path="/group" element={<Suspense fallback={<Spinner />}><GroupListPage /></Suspense>} />
                    <Route path="/group/:id" element={<Suspense fallback={<Spinner />}><GroupPage /></Suspense>} />
                    <Route path="/select-accounts" element={<Suspense fallback={<Spinner />}><AccountSelectionPage /></Suspense>} />
                    <Route path="/showcase" element={<Suspense fallback={<Spinner />}><ContentListPage /></Suspense>} />
                    {/* <Route path="/edit-profile" element={<ProfileEditor userId={NaN} />} /> */}
                    <Route path="/notifications" element={<Suspense fallback={<Spinner />}><NotificationListPage /></Suspense>} />
                    <Route path="/organizations" element={<Suspense fallback={<Spinner />}><OrganizationsPage /></Suspense>} />
                    <Route path="/profile" element={<Suspense fallback={<Spinner />}><ProfilePage /></Suspense>} />
                    <Route path="/requests" element={<Suspense fallback={<Spinner />}><RequestListPage /></Suspense>} />
                    <Route path="/tools" element={<Suspense fallback={<Spinner />}><ToolsPage /></Suspense>} />
                    <Route path="/tariff" element={<Suspense fallback={<Spinner />}><TariffPage /></Suspense>} />
                    <Route path="/downloads" element={null} />
                    <Route path="/users" element={<Suspense fallback={<Spinner />}><UsersPage /></Suspense>} />
                    <Route path="/users/:id" element={<Suspense fallback={<Spinner />}><UserEditorPage /></Suspense>} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </UploadFilesContext.Provider>
    </main>
}

export default AppMain;