import { useEffect, useState } from 'react';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useResetProfilePasswordMutation } from '../../../services/users_v1';
import { Modal } from '../Modal';
import { useLanguage } from '../../../hooks';
import { extractErrorDetail } from '../../common';
import Form from '../Form';
import Button from '../Button';


interface ForgotPasswordModalProps {
    isOpen: boolean,
    onClose: () => void,
}

export function ForgotPasswordModal({ isOpen, onClose }: ForgotPasswordModalProps) {
    const [reset] = useResetProfilePasswordMutation();
    const { t } = useTranslation();

    const [success, setSuccess] = useState<boolean>();
    const [fail, setFail] = useState<string>();
    const [token, setToken] = useState('');
    const lang = useLanguage();

    const successClass = 'popup_message';

    const defaultEmail = {
        email: ''
    };
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultEmail });

    const getFormErrorMessage = (name: keyof typeof errors): JSX.Element | undefined => {
        const e = errors[name];
        return e && <div className="form__input-error-message">{e.message}</div>
    };

    useEffect(() => {
        if (isOpen) {
            setSuccess(false);
            setFail('');
        }
    }, [isOpen]);

    return (
        <Modal className={success ? successClass : 'popup_feedback'} isOpen={isOpen} onClose={() => onClose()}>
            {success ?
                <div className="form-message _success">
                    <div className="form-message__icon"></div>
                    <h2 className="popup__heading form-message__heading">
                        <Trans>
                            modal:MODAL_FORGOT_OK
                        </Trans>
                    </h2>
                    <div className="form-message__copy">
                        <Trans>
                            modal:EMAIL_LINK_FOLLOW
                        </Trans>
                    </div>
                    <div className="form-message__ok">
                        <span className="btn" onClick={() => onClose()}><Trans>modal:SUCCESS_MESSAGE_CONFIRM</Trans></span>
                    </div>
                </div>
                :
                (fail
                    ?
                    <div className="form-message _error">
                        <div className="form-message__icon"></div>
                        <h2 className="popup__heading form-message__heading"><Trans>modal:ERROR_MESSAGE_TITLE</Trans></h2>
                        <div className="form-message__copy">
                            {fail}
                        </div>
                        <div className="form-message__ok">
                            <span className="btn" onClick={() => onClose()}><Trans>modal:ERROR_MESSAGE_CONFIRM</Trans></span>
                        </div>
                    </div>
                    :
                    <Form action="#" className="form-feedback" id="feedback">
                        <h2 className="popup__heading">
                            <Trans>
                                modal:MODAL_FORGOT_Q
                            </Trans>
                        </h2>
                        <div className="form-message__copy">
                            <Trans>
                                modal:MODAL_FORGOT_MESSAGE
                            </Trans>
                        </div>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: t('Email is required'),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t('modal:INVALID_EMAIL')
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                                    <input id={field.name} {...field} placeholder={t('Email')} autoFocus className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                                    {getFormErrorMessage('email')}
                                </div>
                            )}
                        />
                        <SmartCaptcha language={lang} sitekey="ysc1_CGbTGnEniUByIpNrHaWkRb2DEfaigOpUTJY0PFNc79bbb80c" onSuccess={setToken} />

                        {token && <div className="form__submit" style={{ marginTop: '20px' }}>
                            <Button onClick={handleSubmit((data) => {
                                reset({account: data.email, token: token}).unwrap().then(() => {
                                    setSuccess(true);
                                }).catch((e) => {
                                    if (e.data?.response?.status?.msg) {
                                        setFail(e.data?.response?.status?.msg);
                                    } else {
                                        setFail(extractErrorDetail(e));
                                    }
                                })
                            })}>
                                <Trans>
                                    Send
                                </Trans>
                            </Button>
                        </div>}
                    </Form>
                )
            }
        </Modal>
    );
}