import { createPopper } from '@popperjs/core';
import AirDatepicker from 'air-datepicker';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg';
import { ReactComponent as Close } from '../../assets/svg/Close.svg';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';
import { useOutsideAlerter } from '../../hooks';


interface DatePickerProps {
    placeholder: string,
    value?: Date,
    setValue?: (value?: Date) => void,
    className?: string
    maxDate?: Date
}

export function DatePicker({ value, placeholder, setValue, maxDate }: DatePickerProps) {
    const refWrapper = useRef<HTMLDivElement | null>(null);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const [selected, setSelected] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [label, setLabel] = useState(placeholder);

    useOutsideAlerter(refDiv, setActive, refWrapper);

    const dp = useRef<AirDatepicker<HTMLDivElement> | null>(null);

    const calendarMonths = useMemo(() => [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ], []);

    const onSelect = useCallback(({ date }: {
        date: Date | Date[];
        formattedDate: string | string[];
        datepicker: AirDatepicker<HTMLDivElement>;
    }) => {
        const d = date as Date;
        setValue && setValue(d);
        if (date) {
            let textDate = '';
            textDate += d.getDate();
            textDate += ' ';
            textDate += calendarMonths[d.getMonth()];
            textDate += ' ';
            textDate += d.getFullYear();
            setLabel(textDate);
            setSelected(true);
            setActive(false);
        } else {
            setLabel(placeholder);
            setSelected(false);
        }
    }, [calendarMonths, placeholder, setValue])

    // Start init
    useEffect(() => {
        // Save instance for the further update
        if (refDiv.current && refWrapper.current) {
            if (!dp.current) {
                createPopper(refWrapper.current, refDiv.current, {
                    placement: 'bottom-start',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: ['top-start', 'top-end'],
                            },
                        },
                    ],
                });
                dp.current = new AirDatepicker(refDiv.current, { maxDate: maxDate || new Date(), onSelect: onSelect });
            }
        }
    }, [onSelect, active]);

    useEffect(() => {
        if (dp.current) {
            if (active) {
                dp.current.show();
            } else {
                dp.current = null;
            }
        }
    }, [active]);

    useEffect(() => {
        if (!value) {
            setLabel(placeholder);
            setSelected(false);
        } else if (typeof(value) === 'object') {
            dp.current?.selectDate(value);
            setValue && setValue(value);
            let textDate = '';
            textDate += value.getDate();
            textDate += ' ';
            textDate += calendarMonths[value.getMonth()];
            textDate += ' ';
            textDate += value.getFullYear();
            setLabel(textDate);
            setSelected(true);
            setActive(false);
        }
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <div
                ref={refWrapper}
                className={'form__calendar _min' + (selected ? ' _selected' : '') + (active ? ' _active' : '')}
                onClick={() => setActive(!active)}
            >
                <Calendar className="form__calendar-icon" />
                <span className="form__calendar-label">{label}</span>
                <input type="hidden" value="" className="form__calendar-input" />
                <ArrowDown className="form__calendar-arrow" />
                <div className="form__calendar-remove" onClick={(e) => {
                    e.stopPropagation();
                    setValue && setValue(undefined);
                    dp.current?.clear();
                    setSelected(false);
                    setActive(false);
                    setLabel(placeholder);
                }}>
                    <Close />
                </div>
            </div>
            {active && ReactDOM.createPortal(
                <div
                    ref={refDiv}
                    className="form__calendar-body"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        inset: '0px auto auto 0px',
                        margin: '0px',
                        transform: 'translate(20px, 136px)',
                        zIndex: 50,
                    }}
                ></div>, document.body)}
        </>
    );
}