import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { commonApi, roleReducer, accReducer } from '../services/api';
import authReducer from '../services/api';
import { useDispatch } from 'react-redux';
import { userApiV1 } from '../services/users_v1';
import { contentApiV1 } from '../services/content_v1';


const rootReducer = combineReducers({
    [commonApi.reducerPath]: commonApi.reducer,
    [userApiV1.reducerPath]: userApiV1.reducer,
    [contentApiV1.reducerPath]: contentApiV1.reducer,
    auth: authReducer,
    role: roleReducer,
    acc: accReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
            getDefaultMiddleware().concat(commonApi.middleware).concat(userApiV1.middleware).concat(contentApiV1.middleware),
        preloadedState,
    })
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
