import { Link } from 'react-router-dom';
import ImageLoader from '../ImageLoader';

interface TableListNameProps {
    link: string,
    title: string | React.ReactNode,
    url?: string,
    children?: React.ReactNode,
}

function TableListName({ link, title, url, children }: TableListNameProps) {

    return (
        <div className="table-list__name">
            <div className="table-list__name-image empty-image">
                {url && <ImageLoader url={url} />}
            </div>
            <div className="table-list__name-body">
                <span className="table-list__name-link">
                    <Link to={link}>{title}</Link>
                </span>
                {children}
            </div>
        </div>
    );
}

export default TableListName;
