import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks';
import AccountSelectionPage from '../../pages/AccountSelectionPage';
import IndexPage from '../../pages/IndexPage';

function PrivateOutlet() {
    const auth = useAuth();
    return auth?.user ? (
        auth?.account ? (
            <Outlet />
        ) :
        <AccountSelectionPage />
    ) : (
        <IndexPage />
    )
}

export default PrivateOutlet;
