import { ReactComponent as Close } from '../../assets/svg/CloseBig.svg';
import { ReactComponent as Filter } from '../../assets/svg/filter.svg';


interface MobileFilterProps {
    filterVisible: boolean,
    setFilterVisible: (value: boolean) => void,
    children?: React.ReactNode,
    onClean: () => void
}

function MobileFilter({ filterVisible, setFilterVisible, onClean, children }: MobileFilterProps) {
    return (
        <div className={'mobile-filter' + (filterVisible ? ' _show' : '')}>
            <div className="mobile-filter__header">
                <div className="mobile-filter__header-inner">
                    <div className="mobile-filter__header-heading">
                        <Filter />
                        Фильтр
                    </div>
                    <div className="mobile-filter__header-close" onClick={() => setFilterVisible(false)}>
                        <Close />
                    </div>
                </div>
            </div>

            <div className="mobile-filter__body">
                <div className="mobile-filter__body-inner">
                    {children}
                </div>
            </div>
            <div className="mobile-filter__footer">
                <div className="mobile-filter__footer-inner">
                    <span className="mobile-filter__clear" onClick={() => {
                        onClean();
                    }}>
                        Очистить
                    </span>
                    <span className="btn mobile-filter__apply" onClick={() => {
                        setFilterVisible(false);
                    }}>
                        Применить
                    </span>
                </div>
            </div>
        </div>
    );
}

export default MobileFilter;
