import { HTMLAttributes } from 'react';

interface Header1Props extends HTMLAttributes<HTMLHeadingElement> {
    title?: string,
    children?: React.ReactNode;
}

function Header1({ title, children, ...props }: Header1Props) {
    return <h1
        className="content-add__heading"
        title={title}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        {...props}
    >
        {children}
    </h1>;
}

export default Header1;
