import { useEffect, useState } from 'react';
import { useGetCoverMutation } from '../../services/content_v1';

interface ImageLoaderProps {
    url?: string,
    className?: string,
    maxHeight?: string,
    maxWidth?: string,
    alt?: string,
    rounded?: boolean
}

function ImageLoader({ url, className, maxHeight, maxWidth, alt, rounded }: ImageLoaderProps): JSX.Element {
    const [trigger, { data, error, isLoading }] = useGetCoverMutation();

    useEffect(() => {
        trigger(url || '#');
    }, [trigger, url])

    const [src, setSrc] = useState<string>();
    useEffect(() => {
        if (data) {
            setSrc(data);
        }
    }, [data]);

    if (isLoading || !src) {
        return <div style={{ display: 'inline-flex' }}><div style={{ height: maxHeight, width: maxWidth }} /></div>
    }
    if (error) {
        return <></>
    }

    return <img className={className} src={src} style={{ objectFit: 'cover', height: maxHeight, maxWidth: maxWidth, borderRadius: rounded ? '50%' : undefined }} alt={alt || ''} />
}

export default ImageLoader;
