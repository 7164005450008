import { FormHTMLAttributes } from 'react';

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
    children?: React.ReactNode;
}

function Form(props: FormProps) {
    const cls = `form form_required ${props.className}`;
    return (
        <form {...props} className={cls}>
            {props.children}
        </form>
    );
}

export default Form;