import { LabelHTMLAttributes } from 'react';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    children?: React.ReactNode;
}

function Label(props: LabelProps) {
    const cls = `form__label ${props.className || ''}`;
    return (
        <label htmlFor="" {...props} className={cls}>
            {props.children}
        </label>
    );
}

export default Label;